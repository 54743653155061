<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
    mounted() {
        if (!localStorage.getItem('creat')) {
            this.$axios.post('/shop-system/RSA/creatRsa').then(res => {
                localStorage.setItem('creat', JSON.stringify(res.data))
            })
        }

    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
