import axios from 'axios';
import {Toast} from 'vant';
import Vue from 'vue'
import * as base64 from 'js-base64'
import {encrypt} from '../utils/jsencrypt'
import {createCrypto, enAesEncrypt} from '../utils/aesEncryp'


axios.defaults.withCredentials = true
import Router from 'vue-router'

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
// console.log(user)
axios.defaults.timeout = 50000000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UT F-8';
axios.defaults.baseURL = '/api';
axios.interceptors.request.use((config) => {
    const arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    const idvalue = arr[Math.floor(Math.random() * 52)]
    let number = "";
    let m = 7;//这个值可以改变的，对应的生成多少个数字，根据自己需求所改
    for (let q = 0; q < m; q++) {
        number += Math.floor(Math.random() * 10);
    }
    const arra = `${idvalue}${number}`
    const timestamp = Date.parse(new Date())
    let timejia = `${arra}${timestamp}`
    if (config.type === "json") {
        config.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
    } else if (config.data instanceof FormData) {
        config.headers.post['Content-Type'] = 'multipart/form-data'
    }

    if (config.url !== '/shop-system/RSA/creatRsa') {
        config.url = `${config.url}?r=${timejia}`
        if (config.params === undefined && config.method == 'get') {
            config.params = {'x': 1}
        } else if (config.data === undefined && config.method == 'post') {
            config.data = {'x': 1}
        }
        let sign = createCrypto(config.data ? config.data : config.params)//签名
        let s = enAesEncrypt(sign, JSON.stringify(config.data ? config.data : config.params))//aes加密
        let g = encrypt(sign)//rsa加密
        let qqq2 = {s: s, g: g}
        qqq2 = JSON.stringify(qqq2)
        let m = base64.encode(qqq2)//base64加密
        if (config.method === 'post' || config.method === 'put') {
            config.data = {
                'm': m,
                a: JSON.parse(localStorage.getItem('creat')).ailsName ? JSON.parse(localStorage.getItem('creat')).ailsName : Vue.prototype.publicKey.ailsName
            }
        } else {
            config.params = {
                'm': m,
                a: JSON.parse(localStorage.getItem('creat')).ailsName ? JSON.parse(localStorage.getItem('creat')).ailsName : Vue.prototype.publicKey.ailsName
            }
        }
    }
    return config;
}, (error) => {
    Toast("错误的传参" + error, 'fail');
});
axios.interceptors.response.use(
    function (response) {
        return response.data;
    },
    function (err) {
        // return;
        if (err && err.response) {

            switch (err.response.status) {
                case 400:
                    err.message = '请求错误(400)';
                    break;
                case 401:
                    err.message = '未授权，请重新登录(401)';
                    break;
                case 403:
                    err.message = '拒绝访问(403)';
                    break;
                case 404:
                    err.message = '请求出错(404)';
                    break;
                case 408:
                    err.message = '请求超时(408)';
                    break;
                case 500:
                    err.message = '服务器错误(500)';
                    break;
                case 501:
                    err.message = '服务未实现(501)';
                    break;
                case 502:
                    err.message = '网络错误(502)';
                    break;
                case 503:
                    err.message = '服务不可用(503)';
                    break;
                case 504:
                    err.message = '网络超时(504)';
                    break;
                case 505:
                    err.message = 'HTTP版本不受支持(505)';
                    break;
                default:
                    err.message = `连接出错(${err.response.status})!`;
            }
        } else {
            err.message = '当前网络连接不佳，请重新再试'
            // Toast("连接服务器失败")
        }
        // Vue.prototype.$message({
        //   type: "error",
        //   message: err.message
        // });
        // Notice.error({title: err.message});
        // Toast.fail(err.message)
        return {
            data: {
                info: '',
                cate: '',
                page: '',
                code: '0000',
                msg: '当前网络连接不佳，请重新再试',
            }
        }
    }
);
export default axios;
