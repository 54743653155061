import Vue from 'vue';
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        {path: '/', name: 'home', component: () => import("@/views/home.vue")}, // 首页
        {path: '/getVoucher', name: 'getVoucher', component: () => import("@/views/getVoucher.vue")}, // 首页
    ]
})

