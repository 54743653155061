import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from '@/router/axios'
import Vant from 'vant';
import 'vant/lib/index.css';
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
const requireComponent = require.context(
    './components', // 其组件目录的相对路径
    true, // 是否查询其子目录 如果有子目录，将其改为true，如果没有，将其改为false
    /.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(
        camelCase(
            fileName.split('/').pop().replace(/\.\w+$/, '')
        )
    )
    Vue.component(componentName,componentConfig.default || componentConfig)
})

Vue.use(Vant);
Vue.prototype.$axios = axios

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
