//AES依赖包：crypto-js
import CryptoJS from "crypto-js";
//md5加密依赖包：js-md5
import md5 from 'js-md5'
//base64依赖包: npm install --save js-base64
import {Base64} from "js-base64";
//RSA:加密和解密方法
import {encrypt, decrypt} from '@/utils/jsencrypt'

/**
 * 生成aes密钥
 * 1.对数据对象的key转为大写进行比较，按从小到大排序
 * 2.将数据对象转换为字符串用&拼接，':'改为'='
 * 3.md5加密成为为16位密钥
 * */
export function createCrypto(objData) {
    let obj = JSON.parse(JSON.stringify(objData))
    for (let key in obj) {
      obj[key.toUpperCase()] = obj[key]
      delete obj[key]
    }

    let arrKey = Object.keys(obj).sort()
    let sortObj = {}
    for (let i in arrKey) {
      if(obj[arrKey[i]]!==null && obj[arrKey[i]]!==undefined && obj[arrKey[i]]!=="undefined"){
        sortObj[arrKey[i]] = obj[arrKey[i]]
      }
    }
    //结果密钥
    let CRYPTOAESKEY = md5(JSON.stringify(sortObj)).substr(8, 16)
    return CRYPTOAESKEY
}

/**
 * aes加密数据
 * @param secretKey  aes密钥
 * @param plaintText 参数数据
 */
export function enAesEncrypt(secretKey, plaintText) {
  let key = CryptoJS.enc.Utf8.parse(secretKey);
  var srcs = CryptoJS.enc.Utf8.parse(plaintText);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  let jiami = encrypted.toString()
  return jiami
}

/**
 * aes解密数据
 * @param params 需要解密的数据
 * @param keys 密钥
 */
function deAesEncrypt(params, keys) {
  keys = CryptoJS.enc.Utf8.parse(keys);
  // 开始解密
  let decryptStr = CryptoJS.AES.decrypt(params, keys,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }
  );
  decryptStr = CryptoJS.enc.Utf8.stringify(decryptStr).toString();
  //返回 解密后的 字符串
  return decryptStr;
};

//出口：返会base64加密后的数据
export async function gatherData(data) {
  //获取aes密钥
  let aes_key = await createCrypto(data)
  //aes加密数据
  let aes_cnt = await enAesEncrypt(aes_key, data)
  //rsa公钥加密aes密钥
  let rsa_sign = await encrypt(aes_key)
  const str = `rsa_sign=${rsa_sign}&aes_cnt=${aes_cnt}`
  // testDecode(Base64.encode(str))
  return Base64.encode(str)
}

//测试解密
function testDecode(text) {
  //解base64格式
  let str1 = Base64.decode(text)
  //数据处理转换为对象
  let obj = {}
  let str2 = str1.split('&')
  str2.forEach(item => {
    let arr = item.split('=')
    obj[arr[0]] = arr[1]
  })
  //rsa私钥解aes密钥
  let aes_key = decrypt(obj['rsa_sign'])
  //aes密钥解开数据
  let obj2 = deAesEncrypt(obj['aes_cnt'], aes_key)
  console.log('!!@@', obj2)
}



