import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'
import Vue from "vue";

// 密钥对生成 http://web.chacuo.net/netrsakeypair
var publicKey = ''

const privateKey = 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBALlSW9ReFuMYxiNxotNXfeB' +
  '70mXLKvDEqbGMICPiGkFiKXi/30eclbjz3FI/UoNWtLfdnPVy82iPkP9MiEhmspriDw2v8p90iojJCjUymKp1c4t/' +
  '9W+XwxK1wc9B8Ngs2Wmkhdpi80EJhAGaBMxcrmfiDVgsFVqy618XSxYwkkStAgMBAAECgYAUjJhYJRK+ncmitoAKl' +
  'UIE+VXsKaoaRoRWhqYttG2a4Gqe1A/LHgbQGmJM0G6y15N0a+NyqAtrhbfbRz45YQHe0DG6zvk9meddd/qUGHqH6c' +
  'mn7L7NVGfSeKCUuxU5IAfR1MJbwy7BPLSc0RZ+nyuSiMhp4vMvl+mMdHB9xCYo1QJBAPE76eSA1sg2OVdIo+roJbU' +
  'orOdUDpuVW0Dc5M5+830tJpm+wHh8XYiL8D8ZiB2aLUjHxydLLXiyJcfVTLyWoM8CQQDEqk+O2dySXiAxHI7h7gMs' +
  'GsIwQp0Th3szi37FhvPBN9/SBOhU1X7gjBOMwVsV9h6diUAoghThsPM1JZnxiYnDAkEA3P8dZBfgLGT4nQzQFlj8i' +
  'n0sAXTRORlYExEKkF5fJHMjpL/KxdZxPbGts7DpMO1YxQ84VLgbfTH/SJYRCcgYowJASoN491gGYZK2QcVfQa0LmK' +
  'de0ig7GY4omOQOmUrDin7gF9PyL0pg8zU/pwIPv+drvCann3GB9e00iPuBM2n4PwJBALk9ld95R8Wpu3eRqrhNwuO' +
  'jlKYcDLRkl+bc2n3TCHU5+LW1PgkCiJyAoIHfj5IPzsU3G0i/Bcf69TopwyQod1E='

// 加密
export function encrypt(txt) {
  publicKey = JSON.parse(localStorage.getItem('creat')).publicRsa ? JSON.parse(localStorage.getItem('creat')).publicRsa : Vue.prototype.publicKey.publicRsa
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

